
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import { gpf } from "@/utils/global-functions";
import { dataProfile } from "../index";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const servicesV1Public = async () => await import(`@/services/v1/public/index`);

export default defineComponent({
  name: "ModalUpdateProfile",
  components: {
    ModalTemplate,
  },
  setup(_, { emit }) {
    // START --- action form for update profile ------------------------------------------------
    const form = reactive({
      name: "",
      phone: "",
      email: "",
      photo: "",
      nameBanner: "Choose File",
      oversizeBanner: false,
      tempBanner: "",
    });

    const removeBanner = () => {
      form.nameBanner = "Choose File";
      form.photo = "";
      cash("#showImage").attr("src", "");
    };

    const emptyFieldUP = () => {
      const dp = dataProfile;
      const ex = form;
      ex.name = dp.name;
      ex.phone = dp.phone;
      ex.email = dp.email;
      ex.photo = dp.photo;
      ex.nameBanner = "Choose File";
      ex.oversizeBanner = false;
      ex.tempBanner = "";
    };

    onMounted(() => {
      emptyFieldUP();
    });

    const hitUpdateProfile = async () => {
      const { updateProfile } = await servicesV1Public();

      gpf.gLoading.show();

      try {
        const ex = form;
        const body = new FormData();
        body.append("name", ex.name);
        body.append("phone", ex.phone);
        body.append("email", ex.email);
        body.append("photo", ex.photo);

        await updateProfile(body);

        gpf.handleSdsV2("Update Profile Berhasil", "toast");
        gpf.hideModal("#modal-update-profile");
        emit("get-data-profile");
        emptyFieldUP();

        gpf.gLoading.hide();
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        gpf.gLoading.hide();
        gpf.handleEds(e);
      }
    };
    // END --- action form for update profile --------------------------------------------------

    return {
      gpf,
      form,
      hitUpdateProfile,
      removeBanner,
    };
  },
  methods: {
    chooseBanner() {
      const id = "fileBannerDM";
      const btn: any = document.getElementById(id);
      btn.click();
    },
    handleFile(evt: any) {
      const target = evt.target;
      const files = target.files;
      const sizeFiles = target.files[0].size;
      const ex = this.form;

      if (sizeFiles > 1000000) {
        ex.oversizeBanner = true;
        this.removeBanner();
      } else {
        const nameFile = target.files[0].name;
        ex.nameBanner = nameFile;
        ex.photo = files[0];
        ex.oversizeBanner = false;
        // const bodyFormData = new FormData();
        // bodyFormData.append("files", files[0]);

        this.renderImage(target.files[0]);
      }
    },
    renderImage(files: any) {
      // console.log('masuk render image ', files)
      const reader = new FileReader();
      reader.onload = function (e: any) {
        cash("#showImage").attr("src", e.target.result);
      };
      reader.readAsDataURL(files);
    },
  },
});
